var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "relative pb-4 pt-10"
  }, [_c('div', {
    staticClass: "px-4 md:px-10 mx-auto w-full"
  }, [_c('div', [_c('div', {
    staticClass: "flex flex-wrap"
  }, [_c('div', {
    staticClass: "w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
  }, [_c('card-stats', {
    attrs: {
      "statSubtitle": "Total orders",
      "statTitle": _vm.TotalOrder,
      "user": _vm.currentUser,
      "statArrow": "up",
      "filter": "true",
      "action": "Orders",
      "timeserch": _vm.timeserchorders,
      "loading": _vm.loadingorders,
      "from": _vm.from_orders,
      "to": _vm.to_orders,
      "statPercentColor": "text-green-500",
      "statIconName": "local_grocery_store",
      "statIconColor": "bg-indigo-500"
    },
    on: {
      "ChangeTypeDateRevenu": _vm.ChangeTypeDateRevenu,
      "FindRecords": _vm.FindRecords
    }
  })], 1), _vm.currentUser.type == 'Admin' || _vm.currentUser.type == 'CEO' ? _c('div', {
    staticClass: "w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
  }, [_c('card-stats', {
    attrs: {
      "statSubtitle": "Total Sellers",
      "statTitle": _vm.TotalSeller,
      "user": _vm.currentUser,
      "statArrow": "down",
      "statPercentColor": "text-red-500",
      "statIconName": "people",
      "statIconColor": "bg-yellow-500"
    }
  })], 1) : _vm._e(), _vm.currentUser.type == 'Seller' || _vm.currentUser.type == 'Admin' || _vm.currentUser.type == 'CEO' ? _c('div', {
    staticClass: "w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
  }, [_c('card-stats', {
    attrs: {
      "statSubtitle": "Total Products",
      "statTitle": _vm.TotalProduct,
      "user": _vm.currentUser,
      "statArrow": "down",
      "statPercentColor": "text-red-500",
      "statIconName": "local_mall",
      "statIconColor": "bg-orange-500"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
  }, [_c('card-stats', {
    attrs: {
      "statSubtitle": "Confirmation",
      "titleRate": "Confirmation Rate:",
      "datas": _vm.Confirmation,
      "rate": _vm.ConfirmationRate,
      "user": _vm.currentUser,
      "statArrow": "up",
      "filter": "true",
      "min_height": "true",
      "action": "Confirmation",
      "timeserch": _vm.timeserchconfirm,
      "loading": _vm.loadingconfirm,
      "from": _vm.from_confirm,
      "to": _vm.to_confirm,
      "statPercentColor": "text-green-500",
      "statIconName": "timeline",
      "statIconColor": "bg-indigo-500"
    },
    on: {
      "ChangeTypeDateRevenu": _vm.ChangeTypeDateRevenu,
      "FindRecords": _vm.FindRecords
    }
  })], 1), _c('div', {
    staticClass: "w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
  }, [_c('card-stats', {
    attrs: {
      "statSubtitle": "Delivery",
      "titleRate": "Delivery Rate:",
      "user": _vm.currentUser,
      "datas": _vm.Delivery,
      "rate": _vm.DeliveryRate,
      "min_height": "true",
      "statArrow": "up",
      "filter": "true",
      "action": "Delivery",
      "timeserch": _vm.timeserchdelivry,
      "loading": _vm.loadingdelivery,
      "from": _vm.from_delivery,
      "to": _vm.to_delivery,
      "statPercentColor": "text-green-500",
      "statIconName": "timeline",
      "statIconColor": "bg-indigo-500"
    },
    on: {
      "ChangeTypeDateRevenu": _vm.ChangeTypeDateRevenu,
      "FindRecords": _vm.FindRecords
    }
  })], 1), _vm.currentUser.type == 'Seller' || _vm.currentUser.type == 'Admin' || _vm.currentUser.type == 'CEO' ? _c('div', {
    staticClass: "w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
  }, [_c('card-stats', {
    attrs: {
      "statSubtitle": "Accounting",
      "datasAcounting": _vm.Accounting,
      "user": _vm.currentUser,
      "statArrow": "up",
      "filter": "true",
      "min_height": "true",
      "filterSeller": "true",
      "timeserchseller": _vm.timeserchsellerAccounting,
      "action": "Accounting",
      "sellers": _vm.sellers,
      "timeserch": _vm.timeserchaccounting,
      "loading": _vm.loadingaccounting,
      "userType": _vm.currentUser.type,
      "from": _vm.from_accounting,
      "to": _vm.to_accounting,
      "statPercentColor": "text-red-500",
      "statIconName": "monetization_on",
      "statIconColor": "bg-yellow-500"
    },
    on: {
      "ChangeTypeDateRevenu": _vm.ChangeTypeDateRevenu,
      "FindRecords": _vm.FindRecords,
      "ChangeSeller": _vm.ChangeSellerAccounting
    }
  })], 1) : _vm._e(), _vm.currentUser.type == 'Admin' || _vm.currentUser.type == 'CEO' ? _c('div', {
    staticClass: "w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
  }, [_c('card-stats', {
    attrs: {
      "statSubtitle": "Fees santiago",
      "statSubtitle1": "Fees Storeino",
      "statTitle": _vm.Revenue,
      "user": _vm.currentUser,
      "currency": "true",
      "filter": "true",
      "filterSeller": "true",
      "timeserch": _vm.timeserchfees,
      "timeserchseller": _vm.timeserchsellerFees,
      "from": _vm.from_fees,
      "userType": _vm.currentUser.type,
      "action": "Revenue",
      "to": _vm.to_fees,
      "sellers": _vm.sellers,
      "statArrow": "down",
      "statPercentColor": "text-red-500",
      "statIconName": "monetization_on",
      "statIconColor": "bg-orange-500",
      "loading": _vm.loadingfees
    },
    on: {
      "ChangeTypeDateRevenu": _vm.ChangeTypeDateRevenu,
      "FindRecords": _vm.FindRecords,
      "ChangeSeller": _vm.ChangeSellerFees
    }
  })], 1) : _vm._e(), _vm.currentUser.type == 'Seller' || _vm.currentUser.type == 'Admin' || _vm.currentUser.type == 'CEO' ? _c('div', {
    staticClass: "w-full lg:w-6/12 xl:w-4/12 px-4 mb-4"
  }, [_c('card-stats', {
    attrs: {
      "statSubtitle": "Expedition fees paid",
      "statTitle": _vm.FeesExpeditions,
      "user": _vm.currentUser,
      "count": _vm.countExpeditions,
      "countText": "Expeditions",
      "currency": "true",
      "statArrow": "down",
      "statPercentColor": "text-red-500",
      "statIconName": "monetization_on",
      "statIconColor": "bg-orange-500"
    }
  })], 1) : _vm._e()])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }